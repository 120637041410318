.ChatDashboard {
  .SearchBox {
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 13.5px 14px !important;
    }
  }
  .SearchBox--Container {
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid lightgrey;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .MuiGrid-item {
    position: relative;
  }

  .ClientsChatList--Container {
    max-height: calc(100vh - 202px);
    height: calc(100vh - 202px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px; /* Width of the scrollbar */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d7d7d7; /* Color of the scrollbar handle */
      border-radius: 4px; /* Border radius of the scrollbar handle */
    }

    & > * + * {
      border-top-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
    }

    .ClientChat--Item {
      cursor: pointer;
      &:hover {
        background-color: #d3eeff;
      }
      .ClientName--Container {
        flex: 1;
        h6 {
          color: #000;
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        p {
          color: #6a6c6a;
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 145.023%; /* 20.303px */
          letter-spacing: 0.21px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }

      span {
        display: inline-flex;
        padding: 2px 8px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 35px;
        background: #153300;
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .ChatSection--Container {
    max-height: calc(100vh - 120px);
    height: calc(100vh - 120px);
    overflow: hidden;
    position: relative;

    .ChatSection--Header {
      padding: 11px 20px;
      border-radius: 15px 15px 0px 0px;
      position: sticky;
      top: 0;

      h6 {
        color: #000;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .ChatSection--Chats {
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.1) -5%,
          rgba(0, 0, 0, 0) 2%
        ),
        #fff;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 280px);
      height: calc(100vh - 280px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px; /* Width of the scrollbar */
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d7d7d7; /* Color of the scrollbar handle */
        border-radius: 4px; /* Border radius of the scrollbar handle */
      }

      .receivedChat {
        margin-top: 15px;
        .messagesArea {
          display: flex;
          flex-direction: column;
          gap: 5px;
          max-width: 75%;
          div {
            color: #000;
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 145.023%; /* 20.303px */
            letter-spacing: 0.21px;
            border-radius: 10px;
            background: #f5f5f5;
            padding: 10px;
            //max-width: 75%;
            margin-right: auto;
            // &:first-child,
            // &:only-child {
            //   border-bottom-left-radius: 10px;
            //   border-top-left-radius: 0px;
            // }

            // &:last-child {
            //   border-top-left-radius: 0px;
            //   border-bottom-left-radius: 10px;
            // }
          }
        }
      }

      .sentChat {
        flex-direction: row-reverse;
        margin-left: auto;
        margin-top: 15px;
       // margin-right: 10px;
        .messagesArea {
          display: flex;
          flex-direction: column;
          gap: 5px;
          max-width: 75%;
          div {
            color: #000;
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 145.023%; /* 20.303px */
            letter-spacing: 0.21px;
            border-radius: 10px;
            background: #e7f6ff;
            padding: 10px;
            margin-left: auto;
            // &:first-child {
            //   border-bottom-right-radius: 0;
            // }
            // &:last-child {
            //   border-top-right-radius: 0;
            // }
          }
        }
      }
    }

    .SendText--Container {
      background-color: #d3eeff;
      z-index: 10;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      border-top: 1px solid #1b8bcf24;

      .SelectedFile {
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        background: #fff;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 5px 10px;
        gap: 10px;
        & > div {
          display: grid;
          p {
            color: #1b8bcf;
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 145.023%; /* 20.303px */
            letter-spacing: 0.21px;
          }
          span {
            color: #929292;
            font-family: "Roboto", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 145.023%; /* 17.403px */
            letter-spacing: 0.18px;
          }
        }
      }
    }
  }
}
