.practice {
  display: flex;
  overflow-x: hidden;
}

.practice-section {
  /* height: 120rem; */
  position: relative;
  padding: 50px;
  width: 100%;
}

.daily-practice-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}

.practice-container {
  z-index: 1;
  margin: 0;
  width: 100%;
  height: max-content;
  border-radius: 1.25rem;
  padding: 5rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
}

.practice-section-desc {
  color: #252625;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 2.0625rem */
  margin: 1.5rem 0;
}

.lists {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.practice-try-list {
  height: max-content;
  border-radius: 0.5rem;
  background: #f7f4ff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.practice-list,
.done-list {
  padding: 2rem;
  height: max-content;
  border-radius: 0.5rem;
  border: 1px solid #d2c1ff;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.list-title {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
}

.try-list-title {
  font-size: 1.7rem;
  line-height: 2.87838rem; /* 153.514% */
}

.practice-list-title {
  font-size: 1.6rem;
  line-height: 2.30269rem;
}

.list-items {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  cursor: pointer;
}

.try-item-desc {
  width: 32.875rem;
  color: #252625;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.40681rem; /* 175.043% */
}

.done-list .practice-item-desc {
  text-decoration: line-through;
}

.practice-item-desc {
  color: #252625;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9255rem; /* 137.534% */
}

.tick-icon,
.plus-icon {
  width: 1.15rem;
  height: 1.15rem;
  margin-top: 0.5rem;
}

.tick-icon {
  /* margin-top: 0.3rem; */
  /* color: #d6c8ff; */
  border: 3px solid gray;
  border-radius: 50%;
}
.done-list-item .tick-icon {
  border: none;
}

.edit-dialog-icon {
  width: 0.4rem;
  height: 1.75rem;
  cursor: pointer;
}

.edit-popup {
  padding: 0.5rem;
  display: flex;
  width: 1.5rem;
  height: 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  background-color: #f7f4ff;
  border: none;
}

.edit-popup:hover,
.edit-popup:active {
  background-color: #f7f4ff !important;
  border: none !important;
}

.edit-icon {
  color: #885fff;
}

.delete__icon {
  width: 1.3rem;
  height: 1.3rem;
}

.edit,
.delete {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}

.try-list-item {
  display: flex;
  gap: 1rem;
  background-color: transparent;
  border: none;
  text-align: left;
}

.practice-list-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
}

.practice-btn {
  display: flex;
  align-items: center;
  width: max-content;
  height: max-content;
  /* margin: auto; */
  padding: 10px 12px;
  justify-content: flex-start;
  gap: 0.75rem;
  border-radius: 0.57275rem;
  border: 1.145px solid #5e5c61;
  background: #fff;
  cursor: pointer;
}

.add-step-textarea {
  width: 100%;
  display: flex;
  padding: 0.5625rem 1.4375rem 0.6875rem 0.8125rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.8rem;
  border: 1.6px solid #541cf5;
  background: #fff;
}

.add-step-text {
  border: none;
  width: 100%;
}

.add-step-save-text {
  cursor: pointer;
  color: #73589b;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.30269rem; /* 184.216% */
}

.plus-icon-btn {
  width: 1rem;
  height: 1rem;
  color: #885fff;
}

.btn-text {
  color: #885fff;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6485rem; /* 127.928% */
}

.done-list-item {
  display: flex;
  gap: 1rem;
  align-items: start;
}

.daily-practice-btn {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 2.5rem;
  cursor: pointer;
}

@media (max-width: 500px) {
  .daily-practice-sidebar {
    display: none;
  }
  .practice-section {
    padding: 1rem 1rem 100px;
  }

  .tick-icon,
  .plus-icon {
    margin-top: 0;
  }

  .practice-container {
    /* z-index: 10;
    position: absolute;*/
    width: 100%;
    margin: 0;
    height: max-content;
    padding: 0;
    box-shadow: none;
  }
  /* .practice-section-desc {
    display: none;
  } */
  .daily-practice-bg-img {
    display: none;
  }
  /* .sections-heading {
    display: none;
  } */
  .sections-name {
    padding-bottom: 8px;
    font-size: 1.25rem;
    line-height: 1.43919rem; /* 115.135% */
    letter-spacing: 0.03125rem;
    gap: 5rem;
  }
  .practice-try-list {
    width: 100%;
    padding: 1rem;
    max-height: max-content;
    /* gap: 0.75rem; */
  }
  .try-list-title,
  .practice-list-title {
    font-size: 1.125rem;
    line-height: 1.44rem; /* 153.514% */
  }
  .try-item-desc {
    width: 100%;
    font-size: 1rem;
    line-height: 1.2rem; /* 153.514% */
  }
  .list-items {
    gap: 14px;
  }
  .plus-icon {
    width: 1.15rem;
    height: 1.15rem;
    min-width: 1.15rem;
    min-height: 1.15rem;
  }
  .practice-list,
  .done-list {
    padding: 1rem;
    width: 100%;
    height: max-content;
    gap: 1rem;
  }
  .practice-item-desc {
    font-size: 1rem;
    line-height: 1.2rem; /* 137.534% */
  }
  .tick-icon {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
}
