@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.NavigationRail {
  margin-top: 30px !important;
}

.Widget .Widget--Header h6 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.Widget .Widget--Header a {
  color: #1b8bcf;
  text-align: right;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.NotificationTabs {
  border: none;
  gap: 10px;
  padding: 10px 20px;
}
.NotificationTabs .nav-link {
  transition: none;
}
.NotificationTabs .nav-link:hover {
  border: none;
}
.NotificationTabs .nav-link {
  color: #153300 !important;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
}
.NotificationTabs .nav-item .nav-link.active {
  background-color: #153300;
  color: #fff !important;
}

.NotificationPanel {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.NotificationPanel > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-style: solid;
  border-color: #d9d9d9;
}

.NotificationsWidget .tab-content > .active {
  padding-bottom: 30px;
}

.ExpertDashboard {
  max-height: calc(100vh - 61px);
  height: calc(100vh - 61px);
}
.ExpertDashboard .ExpertDashboard--Col {
  max-height: calc(100vh - 61px);
  height: 100%;
  padding-bottom: 30px;
  overflow-y: auto;
}
.ExpertDashboard .ExpertDashboard--Col::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}
.ExpertDashboard .ExpertDashboard--Col::-webkit-scrollbar-thumb {
  background-color: #d7d7d7; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}

.CalendarWidget .Widget--Header .MuiOutlinedInput-input {
  padding: 9px 12px 9px 8px;
  border-radius: 10px !important;
  background: #e7f6ff;
}
.CalendarWidget .Widget--Header .MuiOutlinedInput-notchedOutline {
  border-width: 0;
}

.Days--Container {
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.Content--Days {
  padding: 10px 20px;
}
.Content--Days .Day--Item {
  padding: 0.625rem 0.9375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Content--Days .Day--Item span {
  text-align: center;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Content--Days .Day--Item p {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Content--Days .Day--Item .hasAppointment {
  padding: 2px;
  background-color: red;
  border-radius: 50%;
}
.Content--Days .SelectedDay {
  background-color: #e7f6ff;
  border-radius: 10px;
  border: 1px solid #1b8bcf;
}
.Content--Days .SelectedDay .span {
  color: #1b8bcf;
}

.Content--Data {
  display: grid;
  padding: 10px 20px;
  gap: 5px;
}
.Content--Data .Data--Item {
  border-radius: 0.625rem;
  border: 1px solid #f5f5f5;
  padding: 10px;
  display: flex;
  align-items: start;
  gap: 10px;
}
.Content--Data .Item--details {
  display: flex;
  flex-direction: column;
}
.Content--Data .Item--details > p {
  color: #000;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Content--Data .Item--details > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.Content--Data .Item--details > div p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}

.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header {
  flex-direction: column;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header > div {
  justify-content: space-between;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header > div .ViewCalendar {
  background: #1b8bcf;
  display: flex;
  gap: 5px;
  box-shadow: none;
  padding: 8px 24px;
  border-radius: 10px;
  font-size: 14px;
  text-transform: capitalize;
  font-size: 14px;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header .Options .Option--Item {
  cursor: pointer;
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header .Options .SelectedItem {
  background: #153300;
  color: #fff;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header .Selectors {
  align-items: center;
  margin-left: auto;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header .Selectors p {
  color: #656565;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}
.ExpertAppointmentDashboard .MuiSelect-select {
  min-width: 70px;
  min-height: 0;
  padding: 13.5px;
}
.ExpertAppointmentDashboard .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.ExpertAppointmentDashboard .AppointmentCalendarView {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: -16px;
}

.CalendarView--Content .rbc-header {
  min-height: 42px;
}

.AppointmentCalendarView--Component {
  padding: 22px 10px 40px 20px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 20px;
}
.AppointmentCalendarView--Component .AppointmentCalendarView--Header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AppointmentCalendarView--Component .AppointmentCalendarView--Header .MuiFormControl-root {
  width: 128px;
  border-radius: 15px;
  background-color: #e7f6ff;
}
.AppointmentCalendarView--Component .AppointmentCalendarView--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.AppointmentCalendarView--Component .AppointmentCalendarView--Header span {
  color: #656565;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.AppointmentCalendarView--Component .Calendar--Section {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 10px;
}
.AppointmentCalendarView--Component .Calendar--Section .date-button {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 6px;
  margin: 0 auto;
  border-radius: 10px;
}
.AppointmentCalendarView--Component .Calendar--Section .date-button .day {
  color: #21231e;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AppointmentCalendarView--Component .Calendar--Section .date-button .date {
  color: #21231e;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.AppointmentCalendarView--Component .Calendar--Section .date-button .indicator {
  padding: 2px;
  border-radius: 50%;
  background-color: #000;
}
.AppointmentCalendarView--Component .Calendar--Section .isActive {
  border: 1px solid #1b8bcf;
  background-color: #e7f6ff;
}
.AppointmentCalendarView--Component .Calendar--Section .inactive .day,
.AppointmentCalendarView--Component .Calendar--Section .inactive .date {
  color: #929292;
}
.AppointmentCalendarView--Component .Calendar--Section .inactive .indicator {
  background-color: #929292;
}
.AppointmentCalendarView--Component .Appointment--Item {
  background-color: #f5f5f5;
}
.AppointmentCalendarView--Component .Completed--Item {
  background-color: #fff;
}

.Appointments--Container {
  display: flex;
  flex-direction: column !important;
  gap: 10px;
}
.Appointments--Container .Appointment--Item {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  gap: 10px;
}
.Appointments--Container .Appointment--Item .Item--Details {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.Appointments--Container .Appointment--Item .Item--Details h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
}
.Appointments--Container .Appointment--Item .Item--Details h6 svg {
  color: #d2293d;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}
.Appointments--Container .Appointment--Item .Item--Details span {
  color: #55a51d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.21px;
}
.Appointments--Container .Appointment--Item .Item--Details p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.Appointments--Container .Appointment--Item .CTA--Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.Appointments--Container .Appointment--Item .CTA--Container button {
  background: #f5f5f5;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  color: #414141;
  font-size: 14px;
  flex: 1;
}
.Appointments--Container .Appointment--Item .CTA--Container button svg {
  width: 16px;
  height: 16px;
}
.Appointments--Container .Appointment--Item .CTA--Container button p {
  line-height: 130%;
  text-transform: capitalize;
}
.Appointments--Container .Appointment--Item .CTA--Container button:last-child {
  padding: 8px;
  min-width: 0;
  aspect-ratio: 1/1;
  flex: 0.25;
}
.Appointments--Container .Appointment--Item .CTA--Container button:last-child svg {
  width: 20px;
  height: 20px;
}

.ClientDashboard {
  overflow-x: hidden;
}
.ClientDashboard .ClientDashboard--Header {
  flex-direction: column;
}
.ClientDashboard .ClientDashboard--Header > div {
  justify-content: space-between;
}
.ClientDashboard .ClientDashboard--Header > div .AddAppointment {
  background: #1b8bcf;
  display: flex;
  gap: 5px;
  box-shadow: none;
  padding: 8px 24px;
  border-radius: 10px;
  font-size: 14px;
  text-transform: capitalize;
  font-size: 14px;
}
.ClientDashboard .ClientDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ClientDashboard .ClientDashboard--Header .Options .Option--Item {
  cursor: pointer;
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ClientDashboard .ClientDashboard--Header .Options .SelectedItem {
  background: #153300;
  color: #fff;
}
.ClientDashboard .TableHeader p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.AddApointment--Dialog .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background: rgba(27, 139, 207, 0.8);
}
.AddApointment--Dialog .Dialog--Content .SlotTiming {
  margin-top: 20px;
  align-items: center;
}
.AddApointment--Dialog .Dialog--Content .SlotTiming p {
  color: #929292;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AddApointment--Dialog .Dialog--Content .SlotTiming .SelectedSlot {
  font-weight: 600;
  color: #1b8bcf;
}
.AddApointment--Dialog .Dialog--Content .Timings {
  margin-top: 20px;
}
.AddApointment--Dialog .Dialog--Content .Timings .MuiGrid-item {
  text-align: center;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
.AddApointment--Dialog .Dialog--Content .Timings .MuiGrid-item p {
  color: #929292;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.AddApointment--Dialog .Dialog--Content .Timings .AvailableSlot {
  background-color: #fff;
}
.AddApointment--Dialog .Dialog--Content .Timings .AvailableSlot p {
  color: #000;
}
.AddApointment--Dialog .Dialog--Content .Timings .SelectedSlot {
  border: 1px solid #1b8bcf;
  outline-width: 4px !important;
  outline-style: solid !important;
  outline-color: rgba(27, 139, 207, 0.2) !important;
}
.AddApointment--Dialog .Dialog--Content .Timings .SelectedSlot p {
  color: #1b8bcf;
  font-weight: 600;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container {
  margin-top: 20px;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container h6 {
  color: #21231e;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container .SessionTimings {
  margin-top: 20px;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container .SessionTimings .MuiGrid-item {
  text-align: center;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  background-color: #fff;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container .SessionTimings .MuiGrid-item p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container .SessionTimings .SelectedSTiming {
  border: 1px solid #1b8bcf;
  outline-width: 4px !important;
  outline-style: solid !important;
  outline-color: rgba(27, 139, 207, 0.2) !important;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container .SessionTimings .SelectedSTiming p {
  color: #1b8bcf;
  font-weight: 600;
}
.AddApointment--Dialog .ConfirmBooking {
  display: inline-flex;
  height: 50px;
  padding: 14px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #1b8bcf;
  margin-bottom: 22px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
}

.ClientSelect--MenuItem {
  align-items: center;
}
.ClientSelect--MenuItem img {
  aspect-ratio: 1/1;
}
.ClientSelect--MenuItem h6 {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.ClientSelect--MenuItem p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.ClientSelect--Form label {
  background-color: #fff;
  padding-right: 4px;
}

.ClientDetails--Tabs .MuiTabs-flexContainer {
  border-bottom: 1px solid #d9d9d9;
}
.ClientDetails--Tabs .MuiTabs-flexContainer button {
  text-transform: capitalize;
  padding: 12px 10px !important;
  font-weight: 600 !important;
}
.ClientDetails--Tabs .css-1aquho2-MuiTabs-indicator {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 4px !important;
}

.Tab--Panel {
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.Tab--Panel .Appointment--Item {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}
.Tab--Panel .Appointment--Item .AppointmentStatus {
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Tab--Panel .Appointment--Item .Upcoming {
  color: #1b8bcf;
  background-color: rgba(80, 179, 239, 0.1019607843);
}
.Tab--Panel .Appointment--Item .Completed {
  background-color: rgba(85, 165, 29, 0.1019607843);
  color: #55a51d;
}
.Tab--Panel .Appointment--Item .Cancelled {
  background-color: rgba(210, 41, 61, 0.1019607843);
  color: #d2293d;
}
.Tab--Panel .Appointment--Item .Appointment--Header {
  padding: 10px;
}
.Tab--Panel .Appointment--Item .Appointment--Header p {
  margin-top: 10px;
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.Tab--Panel .Appointment--Item .AppointmentNotes {
  padding: 10px;
  border-top: 1px solid #d9d9d9;
  background: #f5f5f5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Tab--Panel .Appointment--Item .AppointmentNotes h6 {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
}
.Tab--Panel .Appointment--Item .AppointmentNotes p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.Tab--Panel .WorksheetItem {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.Tab--Panel .WorksheetItem h6 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Tab--Panel .WorksheetItem span {
  color: #656565;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.Tab--Panel .NotesItem,
.Tab--Panel .PrescriptionItem {
  padding: 10px;
}
.Tab--Panel .NotesItem h6,
.Tab--Panel .PrescriptionItem h6 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Tab--Panel .NotesItem p,
.Tab--Panel .PrescriptionItem p {
  margin-top: 10px;
  color: #656565;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.Tab--Panel .Screener--Item {
  padding: 19px 25px;
  background-color: #f5f5f5;
  color: #656565;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}
.Tab--Panel .Screener--Item h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Tab--Panel .Screener--Item p {
  color: #656565;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.Tab--Panel .Screener--Item .CTA--Container button {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: flex;
  height: 38px;
  padding: 7px 13px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 32px;
  background: rgba(27, 139, 207, 0.1);
  text-transform: capitalize;
}

.Courses--Panel > * + * {
  border-top-width: 2px;
  border-bottom-width: 0px;
  border-style: solid;
  border-color: #f5f5f5;
  padding-top: 16px;
}
.Courses--Panel .Course--Item {
  display: flex;
  align-items: center;
  gap: 16px;
}
.Courses--Panel .Course--Item img {
  border-radius: 5px;
  -o-object-fit: cover;
     object-fit: cover;
}
.Courses--Panel .Course--Item h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Courses--Panel .Course--Item span {
  display: inline-flex;
  height: 25px;
  padding: 7px 13px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.Courses--Panel .Course--Item .ongoing {
  border-radius: 32px;
  background: rgba(208, 208, 208, 0.19);
  color: #1b8bcf;
}
.Courses--Panel .Course--Item .completed {
  border-radius: 32px;
  background: rgba(85, 165, 29, 0.2);
  color: #153300;
}

.ClientDetailsGrid--Right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.ClientDetailsGrid--Right img {
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ClientDetailsGrid--Right h6 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ClientDetailsGrid--Right span {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.56px;
}
.ClientDetailsGrid--Right .CTA--Container,
.ClientDetailsGrid--Right .ClientActions--Container {
  width: 100%;
}
.ClientDetailsGrid--Right .ClientActions--Container {
  margin: 20px 0;
}
.ClientDetailsGrid--Right .ClientActions--Container button {
  display: inline-flex;
  height: 38px;
  padding: 7px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 32px;
  background: #f5f5f5;
  flex: 1;
  color: #414141;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-transform: capitalize;
}

.Screener--Details {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.Screener--Details .Screener--Header {
  text-align: center;
}
.Screener--Details .Screener--Header h4 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.Screener--Details .Screener--Header span {
  color: #6a6c6a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.Screener--Details .Screener--Header span b {
  color: #000;
}
.Screener--Details .Screener--ClientDetails {
  margin-top: 8px;
  border-radius: 10px;
  border: 1px solid #e7f6ff;
  background: #e7f6ff;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 18px;
}
.Screener--Details .Screener--ClientDetails img {
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.Screener--Details .Screener--ClientDetails div {
  display: flex;
  flex-direction: column;
}
.Screener--Details .Screener--ClientDetails div h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Screener--Details .Screener--ClientDetails div p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.56px;
}
.Screener--Details .Screener--ClientDetails div span {
  color: #6a6c6a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.Screener--Details .Screener--ClientDetails div span b {
  color: #000;
}
.Screener--Details .Screener--Content {
  margin-top: 20px;
  width: 100%;
}
.Screener--Details .Screener--Content table th, .Screener--Details .Screener--Content table td {
  border: 1px solid #D9D9D9;
}
.Screener--Details .Screener--Content table tbody tr:nth-of-type(odd) {
  background-color: #F5F5F5;
}
.Screener--Details .Screener--Content table tbody th:last-child {
  border: none !important;
}
.Screener--Details .Screener--Content .GeneralQuestionsTable tbody tr tr:last-child {
  font-weight: 600;
}

.ScheduleDashboard .ScheduleDashboard--Header {
  justify-content: space-between;
  align-items: center;
}
.ScheduleDashboard .ScheduleDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ScheduleDashboard .ScheduleDashboard--Content {
  flex-direction: column;
}

.PopulateSection--Inputs > div {
  flex: 1;
}

.PopulateSection h4 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.PopulateSection button {
  display: block;
  margin-left: auto;
}

.ManualSection h4 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ManualSection .GoToDate {
  width: 118px;
}
.ManualSection .Selector--Header h4 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ManualSection .ManualSection--Selector {
  margin-top: 20px;
}

.DateSelector--Container button {
  padding: 0 !important;
  min-width: 0;
  display: flex;
  width: 32px;
  height: 55px;
  padding: 8px 41px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  background: #f3f3f3;
  color: #1b8bcf;
}
.DateSelector--Container th {
  padding: 0;
}
.DateSelector--Container .DateSelector--Dates {
  flex: 1;
  justify-content: space-between;
}
.DateSelector--Container .DateSelector--Item {
  height: 55px;
  display: flex;
  flex-direction: column;
  width: 95%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
}
.DateSelector--Container .DateSelector--Item p {
  color: #414141;
  text-align: center;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.DateSelector--Container .DateSelector--Item span {
  color: #414141;
  text-align: center;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.ManualDateSelector--Table th,
.ManualDateSelector--Table td {
  border-bottom: 0 !important;
}
.ManualDateSelector--Table thead th:first-child {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-align: center;
}
.ManualDateSelector--Table tbody {
  margin-top: 15px;
}
.ManualDateSelector--Table tbody tr:first-child td:first-child {
  border-top-left-radius: 10px;
}
.ManualDateSelector--Table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.ManualDateSelector--Table tbody tr td:first-child {
  background-color: #f3f3f3;
}
.ManualDateSelector--Table tbody tr td:first-child div {
  background: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  color: #414141;
  font-size: 14px;
}
.ManualDateSelector--Table tbody .MuiCheckbox-root svg {
  color: #55A51D !important;
}

.redirectToAppointmentsCTA:hover {
  color: #FFF;
  background-color: rgba(21, 101, 192, 0.4784313725);
}

.ExpertWorksheet--Dashboard .ExpertWorksheet--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ExpertAppointmentDashboard--Options {
  justify-content: space-between;
}
.ExpertAppointmentDashboard--Options .Options .Option--Item {
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ExpertAppointmentDashboard--Options .Options .SelectedItem {
  background: #153300;
  color: #fff;
}

.NewWorksheet--Grid .NewWorksheet--Left .Left--Container span {
  color: #1b8bcf;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Container h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Container .MuiFormGroup-root {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Container .MuiFormGroup-root span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Left .NewSection {
  border: 2px dashed #1b8bcf;
}
.NewWorksheet--Grid .NewWorksheet--Left .CustomQuestion {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}
.NewWorksheet--Grid .NewWorksheet--Left .CustomQuestion fieldset {
  margin: 0 !important;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Data > p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Data > div {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Right > div > span {
  color: #d2293d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Right > div > p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Right > div .Actions--Container button {
  flex: 1%;
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #f5f5f5;
  color: #414141;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Right > div .Actions--Container .SaveButton {
  background: #1b8bcf;
  color: #fff;
}
.NewWorksheet--Grid .NewWorksheet--Right > div .dropzone > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed #929292;
  margin-top: 15px;
}

.ViewWorksheet--Table .Table--Profile h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ViewWorksheet--Table .Table--Progress p {
  border-radius: 20px;
  background: rgba(157, 234, 102, 0.2);
  padding: 5px 10px;
  color: #55a51d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: -moz-fit-content;
  width: fit-content;
}

.NewWorksheet--UserDetails .User--Details div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.NewWorksheet--UserDetails .User--Details div span {
  color: #6a6c6a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.NewWorksheet--UserDetails .User--Details div p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.NewWorksheet--UserDetails .User--Details div .complete {
  display: inline-flex;
  padding: 3px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: rgba(157, 234, 102, 0.2);
  color: #55a51d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ExpertArticles--Dashboard .ExpertArticles--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ExpertArticles--Options {
  justify-content: space-between;
}
.ExpertArticles--Options .Options .Option--Item {
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ExpertArticles--Options .Options .SelectedItem {
  background: #153300;
  color: #fff;
}

.AddArticle--Left > div {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.AddArticle--Left p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 10px;
}
.AddArticle--Left .MuiInputBase-root {
  background-color: #fff;
}
.AddArticle--Left .rdw-editor-main {
  min-height: 380px;
  background-color: #fff;
}

.AddArticle--Right > div {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.AddArticle--Right > div p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 15px;
}
.AddArticle--Right > div h6 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.AddArticle--Right > div .dropzone > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed #929292;
  margin-top: 15px;
}
.AddArticle--Right > div .ArticleActions--Container {
  margin-top: 10px;
}
.AddArticle--Right > div .ArticleActions--Container button {
  text-transform: capitalize;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  color: #414141;
}
.AddArticle--Right > div .ArticleActions--Container .PublishButton {
  background: #1b8bcf;
  border-color: #1b8bcf;
  color: #fff;
}

.ExpertPostsDashboard .ExpertPostsDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ExpertNotificationsDashboard .ExpertNotificationsDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ExpertNotificationsDashboard .ExpertNotificationsDashboard--Header .Header--Options {
  margin-top: 20px;
}
.ExpertNotificationsDashboard .ExpertNotificationsDashboard--Header .Header--Options .Option--Item {
  cursor: pointer;
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ExpertNotificationsDashboard .ExpertNotificationsDashboard--Header .Header--Options .SelectedItem {
  background: #153300;
  color: #fff;
}

.ExpertNotificationsDashboard--Content {
  margin-top: 20px;
  border-radius: 10px;
  background-color: #fff;
}
.ExpertNotificationsDashboard--Content h6 {
  padding: 20px;
}
.ExpertNotificationsDashboard--Content .Content--Item > div > * + * {
  border-top-width: 1px !important;
  border-bottom-width: 0px;
  border-color: #d9d9d9;
}
.ExpertNotificationsDashboard--Content .Content--Item .NotificationRowCard {
  border-top: 1px solid #d9d9d9 !important;
}

.ExpertHelpCenter--Dashboard .ExpertHelpCenter--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ExpertHelpCenter--Options {
  justify-content: space-between;
}
.ExpertHelpCenter--Options .Options .Option--Item {
  cursor: pointer;
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ExpertHelpCenter--Options .Options .SelectedItem {
  background: #153300;
  color: #fff;
}

.upload-button {
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e4;
  border-radius: 4px;
  margin-top: 15px;
}

.upload-button-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-right: 1px solid #e3e3e4;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 10px;
  background-color: #f3f3f3;
}

.upload-button-icon {
  margin-right: 4px;
}

.upload-button-input {
  display: none;
}

.selected-files {
  margin-top: 10px;
}

.file-name {
  margin-bottom: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* margin-right: 20px; */
  width: 90%;
}

.SettingsDashboard .ExpertSettingsDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ExpertSettingsDashboard--Options {
  justify-content: space-between;
}
.ExpertSettingsDashboard--Options .Options .Option--Item {
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ExpertSettingsDashboard--Options .Options .SelectedItem {
  background: #153300;
  color: #fff;
}

.ExpertSettingsDashboard--Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.ExpertSettingsDashboard--Content h4 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ExpertSettingsDashboard--Content h6 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 20px;
}
.ExpertSettingsDashboard--Content .css-1xhypcz-MuiStack-root {
  padding-top: 0 !important;
}

.UploadedFiles--Section {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.UploadedFiles--Section .UploadedItem {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.UploadedFiles--Section .UploadedItem img {
  border-radius: 10px;
  border: 2px solid #d9d9d9;
  padding: 2px;
  aspect-ratio: 1/1;
}
.UploadedFiles--Section .UploadedItem p {
  margin-top: 5px;
  color: #21231e;
  text-align: center;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.Content--Section > span {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.ChatDashboard .SearchBox .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 13.5px 14px !important;
}
.ChatDashboard .SearchBox--Container {
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid lightgrey;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ChatDashboard .MuiGrid-item {
  position: relative;
}
.ChatDashboard .ClientsChatList--Container {
  max-height: calc(100vh - 202px);
  height: calc(100vh - 202px);
  overflow-y: scroll;
}
.ChatDashboard .ClientsChatList--Container::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}
.ChatDashboard .ClientsChatList--Container::-webkit-scrollbar-thumb {
  background-color: #d7d7d7; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}
.ChatDashboard .ClientsChatList--Container > * + * {
  border-top-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item {
  cursor: pointer;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item:hover {
  background-color: #d3eeff;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item .ClientName--Container {
  flex: 1;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item .ClientName--Container h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item .ClientName--Container p {
  color: #6a6c6a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%; /* 20.303px */
  letter-spacing: 0.21px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item span {
  display: inline-flex;
  padding: 2px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 35px;
  background: #153300;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ChatDashboard .ChatSection--Container {
  max-height: calc(100vh - 120px);
  height: calc(100vh - 120px);
  overflow: hidden;
  position: relative;
}
.ChatDashboard .ChatSection--Container .ChatSection--Header {
  padding: 11px 20px;
  border-radius: 15px 15px 0px 0px;
  position: sticky;
  top: 0;
}
.ChatDashboard .ChatSection--Container .ChatSection--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) -5%, rgba(0, 0, 0, 0) 2%), #fff;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 280px);
  height: calc(100vh - 280px);
  overflow-y: auto;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats::-webkit-scrollbar-thumb {
  background-color: #d7d7d7; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .receivedChat {
  margin-top: 15px;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .receivedChat .messagesArea {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 75%;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .receivedChat .messagesArea div {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%; /* 20.303px */
  letter-spacing: 0.21px;
  border-radius: 10px;
  background: #f5f5f5;
  padding: 10px;
  margin-right: auto;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .sentChat {
  flex-direction: row-reverse;
  margin-left: auto;
  margin-top: 15px;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .sentChat .messagesArea {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 75%;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .sentChat .messagesArea div {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%; /* 20.303px */
  letter-spacing: 0.21px;
  border-radius: 10px;
  background: #e7f6ff;
  padding: 10px;
  margin-left: auto;
}
.ChatDashboard .ChatSection--Container .SendText--Container {
  background-color: #d3eeff;
  z-index: 10;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  border-top: 1px solid rgba(27, 139, 207, 0.1411764706);
}
.ChatDashboard .ChatSection--Container .SendText--Container .SelectedFile {
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  gap: 10px;
}
.ChatDashboard .ChatSection--Container .SendText--Container .SelectedFile > div {
  display: grid;
}
.ChatDashboard .ChatSection--Container .SendText--Container .SelectedFile > div p {
  color: #1b8bcf;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.023%; /* 20.303px */
  letter-spacing: 0.21px;
}
.ChatDashboard .ChatSection--Container .SendText--Container .SelectedFile > div span {
  color: #929292;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%; /* 17.403px */
  letter-spacing: 0.18px;
}

/*Cards*/
.UpcomingAppointmentCard {
  width: 100%;
  justify-content: space-between;
}
.UpcomingAppointmentCard .name {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 8px;
  margin-bottom: 5px;
  margin-left: -10px;
  margin-right: -10px;
}
.UpcomingAppointmentCard > div {
  width: 100%;
  flex: 1;
}
.UpcomingAppointmentCard > p {
  font-size: 14px !important;
}
.UpcomingAppointmentCard .AppointmentType {
  font-size: 13px !important;
  color: #6a6c6a;
  line-height: 130%;
}

.upcomingAppointment > div,
.default > div {
  border: 1px solid;
}

.upcomingAppointment > div {
  border-color: #50b3ef;
  outline-style: solid !important;
  outline-width: 5px !important;
  outline-color: rgba(80, 179, 239, 0.2117647059) !important;
}
.upcomingAppointment > p {
  color: #50b3ef;
}

.default > div {
  border-color: #f3f3f3;
}

.NotificationRowCard {
  padding: 20px;
  display: flex;
  align-items: start;
  gap: 15px;
}
.NotificationRowCard img {
  width: 40px;
  min-width: 40px;
}
.NotificationRowCard .newAppointmentIcon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f5f5f5;
}
.NotificationRowCard .newAppointmentIcon svg {
  width: 21px;
  height: 21px;
  color: #153300;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.NotificationRowCard .NotificationRowCard--Content .header {
  color: #0e0f0c;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
}
.NotificationRowCard .NotificationRowCard--Content .review {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.NotificationRowCard .NotificationRowCard--Content .Content--Actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
}
.NotificationRowCard .NotificationRowCard--Content .Content--Actions button {
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.NotificationRowCard .NotificationRowCard--Content .Content--Actions .ProfileCTA {
  background-color: #f6fdf0;
  color: #153300;
  text-transform: capitalize;
}
.NotificationRowCard .NotificationRowCard--Content .Content--Actions .JoinCTA {
  background-color: #1b8bcf;
  color: #fff;
}
.NotificationRowCard .newAppointment--details {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.NotificationRowCard .newAppointment--details p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}

.InboxWidget--Item > div {
  width: 100%;
}
.InboxWidget--Item .Item--Contents {
  flex: 1;
}
.InboxWidget--Item .Item--Contents .Contents--Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.InboxWidget--Item .Item--Contents .Contents--Header h6 {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.InboxWidget--Item .Item--Contents .Contents--Header .Item--UnreadMessages {
  padding: 2px 10px;
  border-radius: 5px;
  background: #153300;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.InboxWidget--Item .Item--Contents a {
  text-decoration: none !important;
  gap: 8px;
  margin-top: 5px;
}
.InboxWidget--Item .Item--Contents a p {
  color: #1b8bcf;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.InboxWidget--Item .Item--Subject {
  margin-top: 5px;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 90%;
}

.AppointmentCard .MuiCardContent-root .Card--Top {
  align-items: center;
}
.AppointmentCard .MuiCardContent-root .Card--Top img {
  aspect-ratio: 1/1;
  height: 45px;
  width: 45px;
}
.AppointmentCard .MuiCardContent-root .Card--Top h6 {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.AppointmentCard .MuiCardContent-root .Card--Top p {
  color: #6a6c6a;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.AppointmentCard .MuiCardContent-root .Card--Top svg {
  width: 18px;
  height: 18px;
  fill: #d2293d;
  margin-left: auto;
  margin-bottom: auto;
}
.AppointmentCard .MuiCardContent-root .Card--Timings {
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}
.AppointmentCard .MuiCardContent-root .Card--Timings div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.AppointmentCard .MuiCardContent-root .Card--Timings div svg {
  width: 21px;
  height: 21px;
  fill: #1b8bcf;
}
.AppointmentCard .MuiCardContent-root .Card--Timings div p {
  color: #21231e;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.AppointmentCard .Card--Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AppointmentCard .Card--Actions button {
  background: #f5f5f5;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  color: #414141;
  font-size: 14px;
  flex: 1;
}
.AppointmentCard .Card--Actions button svg {
  width: 16px;
  height: 16px;
}
.AppointmentCard .Card--Actions button p {
  line-height: 130%;
  text-transform: capitalize;
}
.AppointmentCard .Card--Actions button:last-child {
  padding: 8px;
  min-width: 0;
  aspect-ratio: 1/1;
  flex: 0.25;
}
.AppointmentCard .Card--Actions button:last-child svg {
  width: 20px;
  height: 20px;
}

.WorksheetCard .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}
.WorksheetCard .MuiCardContent-root .css-anmq6p-MuiTypography-root {
  margin-bottom: 0 !important;
}
.WorksheetCard .MuiCardContent-root .MuiTypography-b {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.WorksheetCard .MuiCardContent-root .MuiTypography-span {
  color: #656565;
  text-align: center;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.WorksheetCard .MuiCardContent-root .MuiAvatarGroup-root {
  margin-top: 20px;
}

.ArticleCard .MuiCardContent-root .MuiTypography-span {
  color: #000;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
  margin-bottom: 10px;
}
.ArticleCard .MuiCardContent-root .MuiTypography-h6 {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ArticleCard .MuiCardContent-root .ArticleCard--ViewsNComments {
  margin-top: 12px;
}
.ArticleCard .MuiCardContent-root .ArticleCard--ViewsNComments svg {
  color: #1b8bcf;
  width: 15px;
  height: 15px;
}
.ArticleCard .MuiCardContent-root .ArticleCard--ViewsNComments span {
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.ArticleCard .MuiCardActions-root {
  padding-bottom: 24px;
}

.PayoutWidget {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.PayoutWidget .PayoutWidgetCard--Header {
  display: flex;
  align-items: center;
  gap: 16px;
}
.PayoutWidget .PayoutWidgetCard--Header svg {
  width: 35px;
  height: 35px;
  fill: #6A6C6A;
}
.PayoutWidget .PayoutWidgetCard--Header span {
  color: #6a6c6a;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.PayoutWidget h6 {
  color: #000;
  font-size: 32px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ClientDetails--TableRow {
  position: relative;
  height: 68.8px;
}
.ClientDetails--TableRow:first-child {
  border-top-left-radius: 10px;
}
.ClientDetails--TableRow .Table--Name {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.56px;
}
.ClientDetails--TableRow .Table--Name span {
  display: flex;
  align-items: center;
  gap: 5px;
}
.ClientDetails--TableRow .Table--Name p,
.ClientDetails--TableRow .Table--Sessions p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ClientDetails--TableRow .Table--Worksheets p,
.ClientDetails--TableRow .Table--SessionNotes p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ClientDetails--TableRow .Table--Worksheets span,
.ClientDetails--TableRow .Table--SessionNotes span {
  color: #1b8bcf;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ClientDetails--TableRow .Table--SessionNotes p {
  font-size: 16px;
  font-weight: 600;
}
.ClientDetails--TableRow .Table--Screener svg {
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
}
.ClientDetails--TableRow .Table--Actions {
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.ClientDetails--TableRow .Table--Actions button {
  background: rgba(27, 139, 207, 0.1019607843);
  padding: 8px 15px;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  color: #414141;
  font-size: 14px;
  flex: 1;
  max-width: -moz-max-content;
  max-width: max-content;
}
.ClientDetails--TableRow .Table--Actions button svg {
  width: 16px;
  height: 16px;
}
.ClientDetails--TableRow .Table--Actions button p {
  line-height: 130%;
  text-transform: capitalize;
}
.ClientDetails--TableRow .Table--Actions button:last-child {
  padding: 8px;
  min-width: 36px;
  width: 36px;
  max-width: 36px;
  aspect-ratio: 1/1;
  flex: 0.25;
}
.ClientDetails--TableRow .Table--Actions button:last-child svg {
  width: 20px;
  height: 20px;
}
.ClientDetails--TableRow tbody tr td:last-child {
  display: flex;
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
}

.SelectedTableRow::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 62px;
  background: linear-gradient(90deg, rgba(210, 41, 61, 0.1) 0%, rgba(210, 41, 61, 0) 100%);
  border-left: 2px solid #D2293D;
}

.TicketDetails--TableRow {
  position: relative;
  height: 68.8px;
}
.TicketDetails--TableRow:first-child {
  border-top-left-radius: 10px;
}
.TicketDetails--TableRow .Table--SRNO p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.TicketDetails--TableRow .Table--Topic div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.TicketDetails--TableRow .Table--Topic div p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}
.TicketDetails--TableRow .Table--Topic div span {
  color: #656565;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.TicketDetails--TableRow .Table--Detail p {
  color: #656565;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.TicketDetails--TableRow .Ticket--Resolved {
  display: inline-flex;
  height: 30px;
  padding: 4px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid rgba(157, 234, 102, 0.2);
  background: rgba(157, 234, 102, 0.2);
}
.TicketDetails--TableRow .Ticket--Reply {
  display: inline-flex;
  height: 40px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 32px;
  background: rgba(27, 139, 207, 0.1);
}

.PayoutDetails--TableRow {
  height: 68.8px;
}
.PayoutDetails--TableRow:first-child {
  border-top-left-radius: 10px;
}
.PayoutDetails--TableRow .Table--ID p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PayoutDetails--TableRow .Table--Amount > div,
.PayoutDetails--TableRow .Table--DateTime > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.PayoutDetails--TableRow .Table--Amount .debit,
.PayoutDetails--TableRow .Table--DateTime .debit {
  color: #d2293d;
}
.PayoutDetails--TableRow .Table--Amount div p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PayoutDetails--TableRow .Table--Amount div span {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PayoutDetails--TableRow .Table--DateTime div p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PayoutDetails--TableRow .Table--DateTime div span {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PayoutDetails--TableRow .Table--Remarks p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Reschedule--Dialog .Content--Header {
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  padding: 15px;
}
.Reschedule--Dialog .Content--Header img {
  width: 45px;
  height: 45px;
}
.Reschedule--Dialog .Content--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Reschedule--Dialog .Content--Header span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.Reschedule--Dialog h4 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 40px;
  margin-bottom: 12px;
}
.Reschedule--Dialog .SlotTiming {
  margin-top: 20px;
  align-items: center;
}
.Reschedule--Dialog .SlotTiming p {
  color: #929292;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Reschedule--Dialog .SlotTiming .SelectedSlot {
  font-weight: 600;
  color: #1b8bcf;
}
.Reschedule--Dialog .Timings {
  margin-top: 20px;
}
.Reschedule--Dialog .Timings .MuiGrid-item {
  text-align: center;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
.Reschedule--Dialog .Timings .MuiGrid-item p {
  color: #929292;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.Reschedule--Dialog .Timings .AvailableSlot {
  cursor: pointer;
  background-color: #fff;
}
.Reschedule--Dialog .Timings .AvailableSlot p {
  color: #000;
}
.Reschedule--Dialog .Timings .SelectedSlot {
  border: 1px solid #1b8bcf;
  outline-width: 4px !important;
  outline-style: solid !important;
  outline-color: rgba(27, 139, 207, 0.2) !important;
}
.Reschedule--Dialog .Timings .SelectedSlot p {
  color: #1b8bcf;
  font-weight: 600;
}

.Notes--Dialog .Content--Header {
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  padding: 15px;
}
.Notes--Dialog .Content--Header img {
  width: 45px;
  height: 45px;
}
.Notes--Dialog .Content--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Notes--Dialog .Content--Header span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}

.Prescription--Dialog .Content--Header {
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  padding: 15px;
}
.Prescription--Dialog .Content--Header img {
  width: 45px;
  height: 45px;
}
.Prescription--Dialog .Content--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Prescription--Dialog .Content--Header span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}

.Cancel--Dialog .Dialog--Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  gap: 10px;
}
.Cancel--Dialog .Dialog--Content svg {
  width: 48px;
  height: 48px;
  color: #dd263c;
}
.Cancel--Dialog .Content--Header {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  padding: 15px;
}
.Cancel--Dialog .Content--Header img {
  width: 45px;
  height: 45px;
}
.Cancel--Dialog .Content--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Cancel--Dialog .Content--Header span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}

.AddApointment--Dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 100%;
}
.AddApointment--Dialog .AvailableSlot {
  cursor: pointer;
}

.PrimaryCTA {
  border-radius: 10px !important;
  background-color: #1b8bcf !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #FFF !important;
  text-transform: capitalize !important;
}

.PrimaryCTA.Mui-disabled {
  background-color: rgba(55, 172, 243, 0.5607843137) !important;
}

.GreenCTA {
  display: flex;
  height: 50px !important;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background-color: #9dea66 !important;
  color: #21231E !important;
  text-transform: capitalize !important;
}

.GreenCTA.Mui-disabled {
  background-color: rgba(157, 234, 102, 0.6588235294) !important;
}

.RedCTA {
  display: flex;
  height: 50px !important;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background: #DD263C !important;
  color: #FFF !important;
  text-transform: capitalize !important;
}

.bg-white {
  background-color: #FFF !important;
}

.CheckedIcon {
  color: #1B8BCF;
  border: 2px solid #1B8BCF;
  border-radius: 5px;
  width: 27px;
  aspect-ratio: 1/1;
  margin-left: auto;
  margin-right: auto;
}

.DarkDropdown .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  border-radius: 10px !important;
}
.DarkDropdown .MuiMenu-list {
  background: #000;
  border-radius: 10px;
}
.DarkDropdown li {
  color: #FFF;
}

.Dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 100%;
}

.ComingSoon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 540px;
}
.ComingSoon h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 18px;
  margin-bottom: 15px;
}
.ComingSoon p {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}

:root {
  --primary-color: #73589B;
  --button-primary-color: #73589b;
  --secondary-color: #fff;
  --primary-gradient: linear-gradient(93deg, #05f18f -3.22%, #078a54 103.49%);
  --light-blue: #eeebf3;
}

.primary-color {
  background: var(--primary-color);
}

.btn-primary {
  background-color: var(--primary-color);
}

.btn-primary-color {
  background: --button-primary-color;
}

*::-moz-selection {
  background-color: #ccb6ec;
}

*::selection {
  background-color: #ccb6ec;
}

.MuiCheckbox-root {
  color: var(--primary-color) !important;
}

.swiper-pagination-bullet-active {
  background: #73589B !important;
}/*# sourceMappingURL=index.css.map */