p {
  margin-bottom: 0;
}
.ContactCard {
  padding: 10px;
  border-radius: 15px;
  background-color: #fff;
  text-decoration: none;
  .ContactCard--Content {
      display: flex;
      gap: 10px;
  }
  .card-title {
      color: #000;
  }
  .card-img-top {
      width: 100px;
      height: 100px;
      aspect-ratio: 1/1;
      border-radius: 10px !important;
      object-fit: cover;
  }

  .card-body {
      padding: 0 0 0 10px;

      .ContactCard--desc {
          display: flex;
          flex-direction: column;
          gap: 5px;

          & > div {
              display: flex;
              align-items: flex-start;
              gap: 3px;
              color: #6a6c6a;
              fill: #6a6c6a;
              p {
                  font-weight: 400;
                  font-size: 12px;
                  margin-bottom: 0 !important;
              }
          }
      }
  }
}
.bookExpert--Btn {
  text-align: center;
  margin-top: 10px;
  padding: 8px;
  background: #541cf5;
  border-radius: 8px;
  color: #fff;
}
