.kareify--SignIn {
  background: linear-gradient(0deg, #000 -9.26%, rgba(0, 0, 0, 0) 93.82%);
  padding: 100px 0;
}
.kareify--SignIn h2 {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-style: italic;
  font-weight: 600;
  line-height: 140%; /* 56px */
  margin-right: 90px;
}
@media only screen and (max-width: 500px) {
  .kareify--SignIn .SignIn--box {
    margin-right: 0px !important;
  }
  .kareify--SignIn .kareify--SignIn h2 {
    margin-right: 0px !important;
  }
}
.kareify--SignIn .SignIn--box {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.75);
  padding: 60px 0 30px;
  margin-right: 90px;
}
.kareify--SignIn .SignIn--box h6 {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.735px;
  text-align: center;
}
.kareify--SignIn .SignIn--box span {
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.735px;
}
.kareify--SignIn .SignIn--box h3 {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.kareify--SignIn .SignIn--box .horizontal--line hr {
  border-bottom: 2px solid #b0b0b0;
  width: 100%;
}
.kareify--SignIn .SignIn--box .horizontal--line p {
  color: #626262;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kareify--SignIn .SignIn--box p {
  color: #585858;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.kareify--SignIn .SignIn--box p span {
  color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}
.kareify--SignIn .SignIn--box button {
  border-radius: 10px;
  background: #38ab45;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.kareify--SignIn .SignIn--box .MuiInputBase-input {
  background: #fff;
  border-radius: 10px !important;
}/*# sourceMappingURL=SignIn.css.map */