.nav-link.active {
    background-color: #C4E5F9;
    font-weight: 600;
    border: none !important;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    flex: inherit !important;
}

.professional-otp-popUp>.modal-dialog {
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start;
    position: relative !important;
    margin: 5% auto !important;
}

.professional-otp-popUp>.modal-dialog>.modal-content {
    border-radius: 24px !important;
}

.disclaimer-popUp>.modal-dialog {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    margin: auto !important;
    position: relative !important;
}

.payeeDetail>li>button:hover {
    border: none !important;
}

.payeeDetail>li>button:focus {
    border: none !important;
}

.form-outline .form-control.active~.form-label,
.form-outline .form-control:focus~.form-label {
    transform: translateY(-1rem) translateY(.1rem) scale(.8) !important;
}

.form-floating>label {
    position: absolute;
    top: 0;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    left: .75rem;
    padding-top: .37rem;
    pointer-events: none;
    transform-origin: 0 0;
    transition: all .2s ease-out;
    color: rgba(0, 0, 0, .6);
    margin-bottom: 0;
}

.form-control {
    position: relative;
}