.complete-quiz {
  display: flex;
  overflow-x: hidden;
}

.complete-quiz-section {
  position: relative;
  padding: 50px;
  width: 100%;
}

.complete-quiz-container {
  width: 100%;
  height: max-content;
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 3rem;
  z-index: 1;
  margin: auto;
}

.complete-quiz-img {
  width: 6.375rem;
  height: 6.375rem;
  margin-left: 45%;
}

.complete-quiz-question-container {
  width: 98%;
  height: max-content;
  border-radius: 0.72588rem;
  background: #f4f0ff;
  margin: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.green-tick {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0.35rem;
}

.complete-items {
  display: flex;
  width: 51.625rem;
  gap: 0.5rem;
}

.complete-quiz-section-desc {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.6875rem */
  letter-spacing: 0.02813rem;
  margin: 1rem 0 1rem -2rem;
}

.complete-items-text {
  color: #252625;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.93563rem; /* 140.771% */
}

.complete-quiz-btn {
  display: flex;
  margin-top: 2.5rem;
  cursor: pointer;
}

@media (max-width: 500px) {
  .complete-quiz-section {
    height: 50rem;
    padding: 0;
    margin: 0;
  }
  .complete-quiz-sidebar {
    display: none;
  }
  .complete-quiz-img {
    width: 3.1875rem;
    height: 3.1875rem;
    display: flex;
    margin: auto
  }
  .complete-quiz-container {
    padding: 1rem 1rem 70px 1rem;
  }
  .complete-quiz-section-desc {
    width: 100%;
    text-align: left;
    margin-left: 0;
    text-wrap: wrap;
  }
  .complete-quiz-question-container {
    width: 100%;
    max-height: max-content;
    margin-left: 0;
    padding: 1rem;
    gap: 1.5rem;
  }
  .complete-items {
    display: flex;
    width: 100%;
    gap: 0.5rem;
  }
  .complete-items-text {
    font-size: 0.875rem;
    line-height: 1.2rem; /* 140.771% */
  }
  .green-tick {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.2rem;
  }
  .complete-quiz-btn {
    gap: 3rem;
  }
}
