.modal-content {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 140%; /* 1.4rem */
  border: none;
  background-color: #f5f1fe;
  border-radius: 0%;
  border-radius: 16px;
}

.modal-content h6 {
  margin: 0.5rem 0;
  font-weight: 600;
}

.modal-content > p {
  margin: 0.5rem 0;
  font-weight: 400;
}
.courseInternal--info-modal .modal-dialog {
  max-width: 600px;
}
.courseInternal--info-modal .modal-content {
  border-radius: 20px !important;
  margin: auto;
}
