.ClientDashboard {
  overflow-x: hidden;
  .ClientDashboard--Header {
    flex-direction: column;

    & > div {
      justify-content: space-between;

      .AddAppointment {
        background: #1b8bcf;
        display: flex;
        gap: 5px;
        box-shadow: none;
        padding: 8px 24px;
        border-radius: 10px;
        font-size: 14px;
        text-transform: capitalize;
        font-size: 14px;
      }
    }
    h2 {
      color: #000;
      font-size: 22px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }

    .Options {
      .Option--Item {
        cursor: pointer;
        padding: 11px 25px;
        border-radius: 30px;
        border: 1px solid #d9d9d9;
        background: #f5f5f5;
        color: #6a6c6a;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .SelectedItem {
        background: #153300;
        color: #fff;
      }
    }
  }

  .TableHeader {
    p {
      color: #000;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}

.AddApointment--Dialog {
  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    background: rgba(27, 139, 207, 0.8);
  }
  .Dialog--Content {
    .SlotTiming {
      margin-top: 20px;
      align-items: center;
      p {
        color: #929292;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .SelectedSlot {
        font-weight: 600;
        color: #1b8bcf;
      }
    }

    .Timings {
      margin-top: 20px;
      .MuiGrid-item {
        text-align: center;
        display: flex;
        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        background-color: #f5f5f5;
        p {
          color: #929292;
          font-size: 14px;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.21px;
        }
      }

      .AvailableSlot {
        background-color: #fff;
        p {
          color: #000;
        }
      }

      .SelectedSlot {
        border: 1px solid #1b8bcf;
        outline-width: 4px !important;
        outline-style: solid !important;
        outline-color: #1b8bcf33 !important;
        p {
          color: #1b8bcf;
          font-weight: 600;
        }
      }
    }

    .SessionDuration--Container {
      margin-top: 20px;
      h6 {
        color: #21231e;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .SessionTimings {
        margin-top: 20px;
        .MuiGrid-item {
          text-align: center;
          display: flex;
          padding: 10px 0px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 5px;
          border: 1px solid #f5f5f5;
          background-color: #fff;

          p {
            color: #000;
            font-size: 14px;
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.21px;
          }
        }

        .SelectedSTiming {
          border: 1px solid #1b8bcf;
          outline-width: 4px !important;
          outline-style: solid !important;
          outline-color: #1b8bcf33 !important;
          p {
            color: #1b8bcf;
            font-weight: 600;
          }
        }
      }
    }
  }

  .ConfirmBooking {
    display: inline-flex;
    height: 50px;
    padding: 14px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #1b8bcf;
    margin-bottom: 22px;
  }
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
}

.ClientSelect--MenuItem {
  align-items: center;
  img {
    aspect-ratio: 1/1;
  }

  h6 {
    color: #000;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
  p {
    color: #000;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}
.ClientSelect--Form {
  label {
    background-color: #fff;
    padding-right: 4px;
  }
}

.ClientDetails--Tabs {
  .MuiTabs-flexContainer {
    border-bottom: 1px solid #d9d9d9;
    button {
      text-transform: capitalize;
      padding: 12px 10px !important;
      font-weight: 600 !important;
    }
  }
  .css-1aquho2-MuiTabs-indicator {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 4px !important;
  }
}

.Tab--Panel {
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .Appointment--Item {
    border-radius: 10px;
    border: 1px solid #d9d9d9;

    .AppointmentStatus {
      padding: 5px 10px;
      border-radius: 30px;
      font-size: 12px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .Upcoming {
      color: #1b8bcf;
      background-color: #50b3ef1a;
    }
    .Completed {
      background-color: #55a51d1a;
      color: #55a51d;
    }
    .Cancelled {
      background-color: #d2293d1a;
      color: #d2293d;
    }
    .Appointment--Header {
      padding: 10px;
      p {
        margin-top: 10px;
        color: #000;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.21px;
      }
    }

    .AppointmentNotes {
      padding: 10px;
      border-top: 1px solid #d9d9d9;
      background: #f5f5f5;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      h6 {
        color: #000;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.24px;
      }

      p {
        color: #000;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
      }
    }
  }

  .WorksheetItem {
    padding: 10px;
    display: flex;
    flex-direction: column;
    h6 {
      color: #000;
      font-size: 18px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    span {
      color: #656565;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 145.023%;
      letter-spacing: 0.21px;
    }
  }

  .NotesItem,
  .PrescriptionItem {
    padding: 10px;
    h6 {
      color: #000;
      font-size: 18px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    p {
      margin-top: 10px;
      color: #656565;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 145.023%;
      letter-spacing: 0.21px;
    }
  }

  .Screener--Item {
    padding: 19px 25px;
    background-color: #f5f5f5;
    color: #656565;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    h6 {
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    p {
      color: #656565;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 145.023%;
      letter-spacing: 0.21px;
    }

    .CTA--Container {
      button {
        color: #000;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        display: flex;
        height: 38px;
        padding: 7px 13px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border-radius: 32px;
        background: rgba(27, 139, 207, 0.1);
        text-transform: capitalize;
      }
    }
  }
}

.Courses--Panel {
  & > * + * {
    border-top-width: 2px;
    border-bottom-width: 0px;
    border-style: solid;
    border-color: #f5f5f5;
    padding-top: 16px;
  }

  .Course--Item {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      border-radius: 5px;
      object-fit: cover;
    }

    h6 {
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    span {
      display: inline-flex;
      height: 25px;
      padding: 7px 13px;
      justify-content: center;
      align-items: center;
      gap: 7px;
      flex-shrink: 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }

    .ongoing {
      border-radius: 32px;
      background: rgba(208, 208, 208, 0.19);
      color: #1b8bcf;
    }
    .completed {
      border-radius: 32px;
      background: rgba(85, 165, 29, 0.2);
      color: #153300;
    }
  }
}

.ClientDetailsGrid--Right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  img {
    border-radius: 50%;
    object-fit: cover;
  }

  h6 {
    color: #000;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  span {
    color: #000;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 145.023%;
    letter-spacing: 0.56px;
  }

  .CTA--Container,
  .ClientActions--Container {
    width: 100%;
  }
  .ClientActions--Container {
    margin: 20px 0;
    button {
      display: inline-flex;
      height: 38px;
      padding: 7px 13px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex-shrink: 0;
      border-radius: 32px;
      background: #f5f5f5;
      flex: 1;
      color: #414141;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      text-transform: capitalize;
    }
  }
}

.Screener--Details {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .Screener--Header {
    text-align: center;
    h4 {
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
    span {
      color: #6a6c6a;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      b {
        color: #000;
      }
    }
  }

  .Screener--ClientDetails {
    margin-top: 8px;
    border-radius: 10px;
    border: 1px solid #e7f6ff;
    background: #e7f6ff;
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 18px;

    img {
      border-radius: 50%;
      object-fit: cover;
    }

    div {
      display: flex;
      flex-direction: column;
      h6 {
        color: #000;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      p {
        color: #000;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 145.023%;
        letter-spacing: 0.56px;
      }
      span {
        color: #6a6c6a;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        b {
          color: #000;
        }
      }
    }
  }

  .Screener--Content{
    margin-top: 20px;
    width: 100%;
    table{
      th, td{
        border: 1px solid #D9D9D9;
      }

      tbody{
        tr{
          &:nth-of-type(odd){
            background-color: #F5F5F5;
          }
        }

        th{
          &:last-child{
            border: none !important;
          }
        }
      }
    }

    .GeneralQuestionsTable{
      tbody{
        tr{
          tr{
            &:last-child{
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}