.library {
  display: flex;
  overflow-x: hidden;
}
.library-section {
  height: 70rem;
  position: relative;
  padding: 20px 50px;
  width: 100%;
}

.library-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  padding: 3.5rem 2.875rem 4.6875rem 2.8125rem;
  /* justify-content: center;
  align-items: center; */
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px #0000001f;
  margin: 0;
  z-index: 10;
  position: relative;
}

.library-mob {
  display: none;
}

.library-name {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.43919rem;
}

.library-sections-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.library-sections-item:hover {
  border-radius: 0.4375rem;
  background: linear-gradient(90deg, #73589b 0.09%, #8456c9 99.93%);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.library-sections-item {
  cursor: pointer;
  width: 100%;
  display: flex;
  padding: 1.75rem 2.25rem;
  margin: 0;
  border-radius: 0.4375rem;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
}

.library-sections-item:hover .modules-icons,
.library-sections-item:hover .modules-text {
  color: #fff;
}

.bookmark-icon {
  width: 1.4rem;
  min-width: 1.4rem;
  min-height: 1.4rem;
  height: 1.4rem;
}

.library-bookmark {
  display: none;
}
.library-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@media (max-width: 500px) {
  .library {
    height: 50rem;
    max-height: 50rem;
  }
  .library-section {
    position: relative;
    padding: 0;
    height: 100%;
  }
  .library-sidebar,
  .library-name {
    display: none;
  }

  .library-container {
    width: 97%;
    height: max-content;
    margin: 5rem auto;
    padding: 2.5rem 16px;
  }
  .library-mob {
    display: flex;
    width: 21.0625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .library-mob-heading1 {
    width: 4.5625rem;
    height: 1.1875rem;
    color: #8a8a8a;
    font-family: "Roboto", sans-serif;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.43919rem; /* 191.892% */
  }
  .library-mob-heading2 {
    width: 100%;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.43919rem; /* 115.135% */
  }
  .library-mob-heading3 {
    color: #6d6d6d;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.43919rem; /* 127.928% */
    letter-spacing: 0.03125rem;
  }

  .library-sections-item {
    cursor: pointer;
    width: 100%;
    display: flex;
    padding: 28px 15px;
    margin:  0;
    justify-content: space-between;
    border-radius: 0.4375rem;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  }
  .modules-text {
    text-align: left;
    font-size: 1rem;
  }
  .library-bookmark {
    display: block;
  }
}
