.disclaimer--popup h4 {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 35.943px; /* 128.366% */
}
.disclaimer--popup p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}
.disclaimer--popup .disclaimer-points {
  border-radius: 16px;
  border: 2px solid #ebebeb;
  height: 40vh;
  overflow-y: scroll;
  padding: 10px 15px 20px;
}
.disclaimer--popup .modal-dialog {
  height: 60vh !important;
}
.disclaimer--popup .modal-body button {
  border-radius: 10px;
  background: var(--button-primary-color) !important;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.018px;
  display: block;
  margin: 0 auto;
}
.disclaimer--popup .modal-body .MuiFormControlLabel-label span {
  color: #696969;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.disclaimer--popup .modal-body .MuiFormControlLabel-label span a {
  color: #696969;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}
.disclaimer--popup button {
  border-radius: 10px;
  background: var(--button-primary-color) !important;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.018px;
  display: block;
  margin: 0 auto;
}

.otp--popup .modal-content {
  border-radius: 20px;
  background: #fff;
}
.otp--popup .modal-body {
  padding: 40px 30px;
}
.otp--popup .modal-body .otp--header h3 {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.otp--popup .modal-body p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 24px */
}
.otp--popup .modal-body p span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.otp--popup .modal-body .css-v4u5dn-MuiInputBase-root-MuiInput-root::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
.otp--popup .modal-body .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.02px;
}
.otp--popup .modal-body .otp--resend p {
  color: #767575;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.016px;
}
.otp--popup .modal-body .otp--continue-btn {
  background: var(--button-primary-color) !important;
}/*# sourceMappingURL=SignUp.css.map */