:root {
  --main: rgb(115, 88, 155);
}

.home-section {
  /* width: 100vw; */
  width: 100%;
  height: max-content;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.home-upper-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

.home-heading1 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 2.7rem;
  font-size: 1.2rem;
}

.home-heading2 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 150%;
  font-size: 2rem;
}

.home-sub-heading {
  width: 100%;
  display: flex;
  gap: 5rem;
}

.home-btns {
  width: 25px;
  height: 35px;
  /* margin-top: -3.2rem; */
  /* margin-left: 35rem; */
  display: flex;
  gap: 0.8rem;
  align-items: center;
}
.home-btns img {
  object-fit: contain;
  cursor: pointer;
}
/* .home-btns img {
  height: 2.1rem;
  width: 2.1rem;
} */

/* progress */
.progress-section {
  margin-top: 1rem;
  width: 29rem;
  height: 12rem;
  padding: 1rem;
}

.progress-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.65rem;
}

.progress-container {
  width: 100%;
  height: 8.6rem;
  box-shadow: 0px 4px 20px 0px #0000001f;
  border-radius: 1rem;
  margin-top: 0.5rem;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.progress-sub {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin-top: 0.5rem;
}

.progress-text {
  color: #51ac10;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.51856rem; /* 201.486% */
}

/* video section */
.video-section {
  margin-top: 2rem;
  width: 100% !important;
  height: auto;
  border-radius: 1rem;
}
.video-section > div {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16/9;
}
.video-section > iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16/9;
}


/* module section */

/* todo: adjust height for more modules */
.modulesss {
  width: 100%;
  height: max-content;
  margin-top: 50px;
}
.module--item {
  border-radius: 1.30481rem;
  box-shadow: 0px 4.17532px 17.7451px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 100%;
  max-width: 100%;
  position: relative;
}
.module--item::before {
  content: "";
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgb(5, 5, 5);
  background: linear-gradient(
    8deg,
    rgba(5, 5, 5, 1) 0%,
    rgba(0, 0, 0, 0.7040589917607668) 48%,
    rgba(0, 0, 0, 0.03179008419774154) 71%
  );
  border-radius: 1.30481rem;
}
.module-img {
  width: 100%;
  height: auto;
  aspect-ratio: 2/3;
  cursor: pointer;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-radius: 1.30481rem;
}

.module-main-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.5rem;
  margin-left: 0;
}

.module-sub {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 1rem;
  line-height: 1.6rem;
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
}

.modulesss .module-container {
  display: grid;
  /* max-height: max-content; */
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 1rem;
}

/* .module {
  width: 30rem;
  height: 36rem;
} */

.module-text {
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 0rem 0rem 1.30481rem 1.30481rem;
  height: auto;
  flex: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* background: #fff; */
}

.module--heading {
  color: #323232;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 129.472% */
  color: #fff;
}

.module-sub-heading {
  color: #616161;
  font-family: "Roboto", sans-serif;
  font-size: 0.85rem;
  font-style: italic;
  font-weight: 400;
  line-height: 120%; /* 135.946% */
  color: #fff;
}

@media (max-width: 450px) {
  .home-sidebar {
    display: none;
  }
  .home-section {
    padding: 0;
  }
  .home-heading1 {
    font-size: 1rem;
  }
  .home-heading2 {
    font-size: 1.25rem;
  }
  .home-upper-section {
    padding: 16px;
    gap: 0.5rem;
  }
  .home-sub-heading {
    flex-direction: column;
    gap: 0.5rem;
  }
  .progress-section {
    margin-top: 2rem;
    width: 100%;
    height: auto;
    padding: 1rem;
  }
  .progress-heading {
    font-size: 1.25rem;
  }
  .video-section {
    margin-top: 2rem;

    border-radius: 1rem;
  }
  .modulesss {
    padding: 1rem 1rem 5rem 1rem;
  }
  .modulesss .module-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 2rem;
  }
  .module-img {
    width: 100%;
    height: auto;
    /* aspect-ratio: 3/2; */
    cursor: pointer;
  }
  .module-text {
    /* width: 20rem; */
    height: 33%;
    flex: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .module--heading {
    font-size: 16px;
    line-height: 120%;
    color: #fff;
  }
  .module-sub-heading {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
  }
  .module-text img {
    width: 43px;
    height: 43px;
    max-width: 43px;
    max-height: 43px;
    object-fit: contain;
  }
  .module--item{
    min-width: 73%;
    max-width: 73%;
  }
}
