.hero-section {
  width: 100%;
  height: 75vh;
  min-height: 60vh;
  align-items: center;
  /* position: relative; */
  display: flex;
  flex-direction: row;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hero-main {
  /* margin-left: 15rem; */
  margin-top: -5rem;
  display: flex;
  flex-direction: column;
}

/* .video {
  min-width: 80%;
  max-width: 100%;
  height: 100%;
  margin-left: -28rem;
} */

.bg-image {
  /* height: 20rem;
  width: 10rem; */
  /* height: 38.6875rem; */
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  animation: fadeIn 3s ease-in-out forwards;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.hero-section > div {
  max-width: 1080px;
  width: 100%;
}

.hero-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
}

.hero-left {
  width: 70%;
  position: relative;
  min-height: 21rem;
  animation: slideUp 2s ease-in-out forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.hero-blueHeading {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.375rem;
  color: #4b378e;
  line-height: 140%;
  width: 100%;
}
.hero-blueHeading--mobile {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #4b378e;
  line-height: 140%;
  width: 100%;
  padding: 20px;
  display: none;
}

.hero-heading {
  font-family: "Roboto", sans-serif;
  width: 80%;
  font-weight: 700;
  font-size: 3rem;
  color: #1f1f1f;
  line-height: 140%;
}

.hero-selfPaced {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 20px;
  background-color: #f2f4f7;
  border-radius: 1rem;
  width: max-content;
  padding: 0.5rem;
  color: #4f4f4f;
}

.hero-info {
  display: flex;
  /* width: 40rem; */
  margin: 1rem 0;
}

.hero-enrollments {
  width: 283px;
  height: 30px;
  gap: 0.3rem;
  display: flex;
  flex-direction: row;
}

.hero-rating {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  width: 74px;
  height: 31px;
}

.hero-infoText {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 30px;
  align-items: center;
}

.hero-desc {
  width: 80%;
  /* height: 90px; */
  text-align: justify;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 30px;
  color: #000000;
}

.hero-read-more {
  cursor: pointer;
  overflow: auto;
  font-weight: 700;
  text-decoration: underline;
  transition: 1s ease-in-out;
  margin-left: 10px;
  text-underline-offset: 4px;
}

.hero-btn {
  margin-top: 1.2rem;
  margin-bottom: 1rem;
  width: 16rem;
  height: 3.25rem;
  top: 34rem;
  left: 16rem;
  gap: 1.5px;
  border: none;
  padding: 0.8rem 3.1rem;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;

  border-radius: 8px;
  /* border: 1.067px solid var(--button-primary-color); */
  background: var(--button-primary-color);
}
.hero-btn .MuiCircularProgress-root{
  width: 20px !important;
  height: 20px !important;
}
.hero-btn:disabled {
  background: #73589ba6;
}

.hero-whiteLayout {
  width: 75rem;
  height: 6.25rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 52%);
  border-radius: 1rem;
  padding: 1.8rem 5.5rem;
  gap: 1.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border: 10px white;
  background-color: white;
  animation: fadeIn 3s ease-in-out forwards;
  box-shadow: 0px 1px 5px 0px #0000001a;
}

.hero-layout-items {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 10px;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  position: relative;
  line-height: 1.6rem;
}

.hero-layout-items:hover {
  cursor: pointer;
}

.hero-layout-items::before {
  content: "";
  position: absolute;
  bottom: -8px;
  margin-left: 3px;
  height: 3px;
  width: 0px;
  border-radius: 50px;
  background-color: rgb(115, 88, 155);
  transition: width 1s ease-in-out;
}

.hero-layout-items:hover::before {
  width: 100%;
}

.hero-side-btn {
  position: relative;
}

.hero-side-buttons__container {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  /* position: absolute;
  top: 6.25rem;
  left: -3.43rem; */
  /* animation: slideUp 3s ease-in-out forwards; */
}
.hero-side-buttons__container img {
  min-width: 40px;
}

@media (max-width: 500px) {
  .hero-section {
    min-height: 79vh;
    /* max-height: 100vh; */
    margin-top: 2rem;
  }
  .hero-blueHeading--mobile {
    display: block;
    font-weight: 400;
  }
  .hero-blueHeading {
    display: none;
  }
  .hero-info {
    gap: 20px;
  }
  .hero-info img {
    width: 24.8px;
    height: 24.8px;
  }
  .hero-infoText {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .hero-enrollments {
    width: max-content;
  }
  /* .video {
    min-width: 50%;
    width: 50%;
    height: 35rem;
    margin-top: -38rem;
    margin-left: -23.5rem;
  } */
  .hero-main {
    position: relative;
    padding: 2rem;
    margin-left: -1rem;
    display: flex;
    flex-direction: column;
  }
  .hero-heading {
    font-size: 1.65rem;
    line-height: 2.1rem;
    width: 100%;
    min-width: 311px;
  }
  .hero-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2rem;
    margin-top: 10rem;
  }
  .hero-left {
    margin-left: -0.5rem;
    width: 70%;
    min-height: 21rem;
    animation: slideUp 2s ease-in-out forwards;
  }
  .hero-selfPaced {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 20px;
    background-color: #f2f4f7;
    border-radius: 1rem;
    width: max-content;
    padding: 0.5rem;
    color: #4f4f4f;
  }
  .hero-info {
    width: 150%;
  }
  .hero-desc {
    width: 100%;
    font-size: 1rem;
    line-height: 150%;
    height: max-content;
    /* overflow: hidden; */
  }
  .hero-btn {
    margin: 2.75rem auto 1rem auto;
    display: flex;
  }
  .hero-whiteLayout {
    height: 5.5rem;
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
    transform: none;
    padding: 0.5rem;
    gap: 0.5rem;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .hero-layout-items {
    flex-basis: 48%; /* Adjust the width as needed */
    display: flex;
    align-items: center;
    /* margin-left: -0.75rem; */
    padding-left: 1rem;
    gap: 5px;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 0.7rem;
    position: relative;
    line-height: 1rem;
  }
  /* .hero-layout-items:last-child {
    margin-left: -5rem;
  } */
  .abc {
    gap: 0.5rem;
    flex-direction: row;
    position: absolute;
    top: 2.5rem;
    left: 7.5rem;
  }
}

/*  */
.course--hero {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 110%;
  position: relative;
  max-height: 100%;
  width: 100%;
  max-width: 100vw;
  margin: auto;
}

.course--hero__video,
.course--hero__left {
  flex: 1;
}
.course--hero__left {
  display: flex;
  gap: 20px;
  padding: 1rem 0 3rem 10rem;
}
.course--hero__video .video {
  width: 100% !important;
  height: 100vh !important;
}
/* .course--hero__video .video > div {
  margin-left: -4rem;
} */
.course--hero__video .video iframe {
  width: 100% !important;
  height: 100%;
}

.hero-side-buttons {
  height: max-content;
  margin: auto 0;
}

@media (max-width: 500px) {
  .course--hero {
    flex-direction: column-reverse;
    /* gap: 50px; */
    /* margin-top: 2rem; */
    max-height: fit-content;
  }
  .course--hero__video {
    width: 100%;
  }
  .course--hero__video .video {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16/9;
  }
  .course--hero__left {
    padding: 20px;
    /* margin-top: -3rem; */
  }
  .hero-side-buttons {
    display: none;
  }
  /* .course--hero__content {
    height: 490px;
  } */
  .course--hero__video .video iframe {
    width: 91% !important;
    margin: auto;
    display: flex;
  }
  .course--hero__video .video > div {
    margin-left: 0;
  }
  .hero-layout-items img {
    width: 15.43px;
    height: 15.43px;
    object-fit: contain;
  }
  .hero-layout-items {
    padding-left: 0;
    flex-basis: auto;
  }
  .hero-whiteLayout {
    height: 100%;
    padding: 1.5rem 1rem;
    gap: 25px 20px;
  }
}
