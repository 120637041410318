.search_bar:focus {
  border: none;
  outline: none;
}

.custom-menu-items {
  padding-left: 0;
}

.custom-menu-item {
  font-size: 1rem;
  list-style: none;

  cursor: pointer;

  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  align-items: center;
}

.custom-menu-item:hover {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.menu-icon {
  margin-right: 10px; /* Adjust the spacing between the icon and text */
}

.menu-text {
  display: inline-block;
  vertical-align: middle; /* Adjust the vertical alignment as needed */
}

.icons {
  display: none;
}

.options_tabs:hover .icons {
  display: inline;
}

.userdashboard--Navbar {
  box-shadow: 0 0 20px 0 rgba(63, 63, 63, 0.1);
  .container-fluid {
    padding: 0 50px;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding: 0 20px;
      gap: 20px;
      & > div {
        flex: 1;
        width: 100%;
      }
    }
  }
  .userdashboard--Search {
    @media only screen and (max-width: 600px) {
      display: none !important;
    }
  }
  .userdashboard--Search__BTN {
    @media only screen and (max-width: 600px) {
      display: block !important;
    }
  }
}

.listdrop {
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/** cards styling**/
/* SoftUICardLayout.css */

/*
  .dashboard_card::-webkit-scrollbar {
    display: none;
  }
  
  .dashboard_card {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  }
 */

.left-card,
.cardsl {
  background-color: #fefefe;
  border-radius: 10px;
}

.rectangle-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(10, 10, 10, 0.1);
}

.scroll-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(10, 10, 10, 0.155);
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  min-width: 400px;
  max-width: 400px;
  width: 100%;
}

.assessment-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(10, 10, 10, 0.155);
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  min-width: 200px;
  max-width: 200px;
  width: 100%;
}
.cardfooter {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.scroll-card img {
  width: 65px;
  height: 65px;

  /* margin-bottom: 10px; */
}

.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.title1,
.title2 {
  font-size: 15px;
  font-weight: bold;
}

.button {
  background-color: #3498db;
  color: #fff;
  font-weight: bold;

  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.inputfeild:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.inputrow {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.inputrow {
  border: 1px solid #610c9f;
}

.userDashboard--moods,
.userDashboard--appointments,
.userDashboard--stella {
  position: relative;
  padding: 10px;
  flex: 1;
  padding: 15px 18px;
  img.rounded {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px !important;
  }
  .card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
  }
}
.userDashboard--stella {
  flex: 1.25;
  .userDashboard--stella__items {
    @media only screen and (max-width: 600px) {
      span {
        flex: 1;
      }
    }
  }
}

.userDashboard--cardsContainer {
  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.professionalHelp--Card {
  @media only screen and (max-width: 600px) {
    .rectangle-card {
      & > div {
        justify-content: center;
        img {
          margin: auto;
        }
        h6 {
          text-align: center;
        }
      }
    }
  }
}

.course_popup_conform_button {
  background-color: #73589b;
  display: flex;
  margin: 16px auto 0px;
  color: #ffffff;
  font-weight: 600;
  padding: 8px 25px;
  border-radius: 5px;
  border: none;
}

.course_popup_conform_text {
  text-align: left;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 25px;
  color: #000000;
  font-size: 18px;
}
