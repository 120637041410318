.TicketDetails--TableRow {
  position: relative;
  height: 68.8px;
  &:first-child {
    border-top-left-radius: 10px;
  }

  .Table--SRNO {
    p {
      color: #000;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .Table--Topic {
    div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        color: #000;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        white-space: nowrap;
      }

      span {
        color: #656565;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .Table--Detail {
    p {
      color: #656565;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }

  .Ticket--Resolved {
    display: inline-flex;
    height: 30px;
    padding: 4px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(157, 234, 102, 0.2);
    background: rgba(157, 234, 102, 0.2);
  }

  .Ticket--Reply {
    display: inline-flex;
    height: 40px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 32px;
    background: rgba(27, 139, 207, 0.1);
  }
}
