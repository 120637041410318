.journal {
  overflow-x: hidden;
  display: flex;
}

.journal-section {
  /* height: 74rem; */
  width: 100%;
  position: relative;
  padding: 20px 50px;
}

.journal-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.journal-container {
  position: relative;
  z-index: 1;
  width: 100%;
  /* height: 63rem; */
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  padding: 3rem;
  margin: 0;
}

.journal-sections-heading {
  color: #6d6d6d;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.43919rem;
  letter-spacing: 0.03125rem;
}

.journal-section-name {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.journal-entries {
  display: flex;
  gap: 2rem;
}

.new-entry {
  width: 100%;
}

.new-entry-title {
  width: 100%;
  color: #252625;
  font-family: "Roboto", sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 2.0625rem */
}

.new-entry-input-title {
  margin: 2rem 0rem;
  width: 100%;
  height: 3rem;
  border-radius: 0.675rem;
  background: #fff;
  box-shadow: 0px 4.8px 18px 0px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-input,
.text-input {
  border: none;
  margin: 0;
  padding: 0.75rem;
  /* width: 17.82738rem; */
  height: 1.8rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  /* font-style: italic; */
  font-weight: 400;
  line-height: 100%; /* 1.56rem */
  width: 100% !important;
}

.text-input {
  /* margin-top: 1rem; */
  width: 100%;
  height: 28rem;
  overflow: hidden;
}

.new-entry-input-desc {
  width: 100%;
  height: 16.6875rem;
  border-radius: 0.675rem;
  background: #fff;
  box-shadow: 0px 4.8px 18px 0px rgba(0, 0, 0, 0.09);
  margin: 2rem 0rem;
  overflow: hidden;
  padding: 15px 0px;
}

.new-entry-btn {
  border: none;
  width: 100%;
  height: 3.25rem;
  gap: 1.5px;
  border-radius: 10px;
  padding: 0.8rem;
  background-color: var(--primary-color);
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;
  text-align: center;
}

.previous-entries {
  width: 100%;
  height: max-content;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.prev-entries-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* align-items: center; */
  gap: 12.125rem;
}

.prev-entries-title {
  color: #100f11;
  font-family: "Roboto", sans-serif;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.prev-entries-date {
  /* width: 9.625rem; */
  height: 1.5625rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-decoration-line: underline;
}

.calendar-icon {
  display: none;
}

.prev-entries-items {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  padding: 0 5px 20px 5px;
}

.prev-entries-item {
  padding: 1rem;
  width: 100%;
  height: max-content;
  border-radius: 0.49231rem;
  background: #fff;
  box-shadow: 0px 4.50137px 27.00824px 0px rgba(0, 0, 0, 0.1);
}

.prev-item-date {
  width: 3.6rem;
  color: #8a8a8a;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.35rem */
  letter-spacing: 0.018rem;
}

.prev-item-content {
  display: flex;
  width: 30.625rem;
}

.prev-item-text {
  display: flex;
  flex-direction: column;
}

.prev-item-title {
  display: flex;
  gap: 0.5rem;
}

.prev-item-title-text {
  width: 100%;
  color: #6f4e9f;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.875rem */
  letter-spacing: 0.025rem;
  padding-right: 15px;
}

.prev-item-desc {
  color: #939393;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.875rem */
  letter-spacing: 0.03125rem;
  width: 25rem;
  margin-left: 1.5rem;
  text-align: left;
  max-height: 0;
  overflow: hidden;
  transition: all 1.4s ease-in-out;
}
.prev-item-desc.show {
  height: max-content;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: all 1.4s ease-in-out;
  padding-right: 6px;
}

.see-more-icon {
  width: 0.9rem;
  height: 0.45rem;
  margin-top: 0.65rem;
  cursor: pointer;
}

.prev-item-icons {
  display: flex;
  gap: 0.75rem;
}

.edit-icon,
.dlt-icon {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}

@media (max-width: 500px) {
  .journal-section-name{
    font-size: calc(1.325rem + 0.9vw);
  }
  .journal-section {
    /* height: 89rem; */
    margin-bottom: 2rem;
    padding: 20px 16px;
  }
  .journal-sidebar,
  .journal-bg-img {
    display: none;
  }
  .journal-container {
    width: 100%;
    margin: 0;
    height: max-content;
    padding: 0 0 2rem 0;
    box-shadow: none;
  }
  .journal-sections-heading {
    display: block;
    font-size: 0.9rem;
    line-height: 150%;
    margin-top: 0;
  }
  .journal-entries {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .new-entry-title {
    width: 100%;
    font-size: 1rem;
    line-height: 150%;
    /* margin-left: -2.5rem; */
    letter-spacing: 0.0218rem;
  }
  .new-entry-input-title {
    margin: 1rem 0;
    width: 100%;
  }
  .new-entry-input-desc {
    width: 100%;
    height: 17rem;
    margin: 1rem 0;
    padding: 15px 0px;
  }
  .text-input {
    width: 100%;
  }
  .previous-entries {
    margin-top: 1rem;
  }
  .prev-entries-header {
    width: 100%;
    gap: 5rem;
  }
  .prev-entries-title {
    font-size: 1.055rem;
  }
  .prev-entries-date {
    font-size: 0.93781rem;
    display: flex;
    gap: 0.5rem;
  }
  .calendar-icon {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
  }
  .prev-entries-item {
    padding: 1rem;
    width: 100%;
    height: max-content;
    border-radius: 0.41031rem;
    background: #fff;
    box-shadow: 0px 3.75114px 22.50687px 0px rgba(0, 0, 0, 0.1);
  }
  .prev-item-content {
    display: flex;
    width: 70%;
    justify-content: left;
  }
  .prev-item-title-text {
    width: 100%;
    font-size: 1rem;
    letter-spacing: 0.02rem;
    padding-right: 8px;
  }
  .prev-item-desc {
    font-size: 1rem;
    letter-spacing: 0.002188rem;
    width: 18.125rem;
    margin-left: 1.25rem;
    text-align: left;
    margin-top: 16px;
  }
  .prev-item-icons {
    margin-left: -1.5rem;
  }
  .edit-icon,
  .dlt-icon {
    width: 1.6rem;
    height: 1.5rem;
  }
}

/* textarea
{
  line-height: 4ch;
  background-image: linear-gradient(transparent, transparent calc(4ch - 1px), #E7EFF8 0px);
  background-size: 100% 4ch;
} */


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccb6ec;
}


::-webkit-scrollbar-thumb:horizontal{
  background: #ccb6ec;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}