.MediaCard--Container {
  margin: -12px -15px;
  border-radius: 15px;
  .MediaCard {
    border-radius: 15px;
    .card-img-container {
      position: relative;
      cursor: pointer;
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        //background: rgb(0,0,0);
        background: linear-gradient(38deg, rgba(0, 0, 0, 0.7092086834733893) 0%, rgba(255, 255, 255, 0) 86%);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 15px;
      }
      svg {
        width: 29px;
        height: 29px;
        fill: #541cf5;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 50%;
      }
    }
    .card-img-top {
      aspect-ratio: 16/9;
      object-fit: cover;
      border-radius: 15px;
    }

    button {
      background-color: #c4e5f9;
      border: none;
      border-radius: 20px;
      padding: 8px 15px;
      font-weight: 500;
    }
  }
}

.MediaCard--modal {
  .modal-dialog {
    max-width: 756px;
    width: 95%;
    position: fixed;
    top: 50%;
    right: 50% !important;
    transform: translate(50%, -50%) !important;
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      max-width: 756px;
      width: 100%;
      aspect-ratio: 16/9;
      height: auto;

      .VideoIframe {
        width: 100%;
        height: 100%;
        iframe {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      svg {
        width: 80px;
        padding: 15px;
        background: #f1f7ff;
        border-radius: 50%;
        color: #3c5566;
        margin: 48px auto;
      }

      video {
        width: 100%;
      }
      audio {
        max-width: 400px;
        width: 90%;
      }
    }
  }
}
