.ExpertArticles--Dashboard {
  .ExpertArticles--Header {
    h2 {
      color: #000;
      font-size: 22px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }
}

.ExpertArticles--Options {
  justify-content: space-between;

  .Options {
    .Option--Item {
      padding: 11px 25px;
      border-radius: 30px;
      border: 1px solid #d9d9d9;
      background: #f5f5f5;
      color: #6a6c6a;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .SelectedItem {
      background: #153300;
      color: #fff;
    }
  }
}

.AddArticle--Left {
  & > div {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  p {
    color: #000;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 10px;
  }
  .MuiInputBase-root {
    background-color: #fff;
  }
  .rdw-editor-main {
    min-height: 380px;
    background-color: #fff;
  }
}

.AddArticle--Right {
  & > div {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    p {
      color: #000;
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      margin-top: 15px;
    }
    h6 {
      color: #000;
      font-size: 18px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }

    .dropzone {
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 10px;
        border-radius: 10px;
        border: 1px dashed #929292;
        margin-top: 15px;
      }
    }

    .ArticleActions--Container {
      margin-top: 10px;
      button {
        text-transform: capitalize;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #f5f5f5;
        background: #f5f5f5;
        color: #414141;
      }

      .PublishButton {
        background: #1b8bcf;
        border-color: #1b8bcf;
        color: #fff;
      }
    }
  }
}
