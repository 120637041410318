.stella-icon {
  position: fixed;
  z-index: 999;
}

@media screen and (min-width: 600px) {
  .stella-icon {
    right: 0px;
    bottom: 60px;
    height: 140px;
    width: 140px;
  }
}

@media screen and (max-width: 599px) {
  .stella-icon {
    right: 0px;
    bottom: 20px;
    height: 105px;
    width: 105px;
  }
}
