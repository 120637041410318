.details-section {
  max-width: 100%;
  max-height: 100%;
  background: linear-gradient(100.36deg, #f5f1ff 4.42%, rgba(245, 241, 255, 0) 100.68%);

  padding: 10rem 5rem;
  display: flex;
}

.details-main {
  width: 80%;
  max-height: 70%;
  margin: -5rem 0;
  display: flex;
  flex-direction: column;
}

.details-heading {
  width: 130%;
  font-family: "Roboto", sans-serif;
  /* margin-top: -2rem; */
  font-weight: 600;
  font-size: 3rem;
  line-height: 5rem;
  text-align: center;
  /* margin-left: -3rem; */
}

.text-highlight {
  color: rgb(115, 88, 155);
  font-weight: 700;
}

.details-list {
  width: 32rem;
  height: 21rem;
  margin: 2rem;
  /* display: flex;
  flex-direction: row;
  gap: 1rem; */
}

.details-list-items {
  list-style: none;
  list-style-image: url("../../components/courseNew/assets/green_check_mark.png");
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 2%;
  margin-bottom: 1rem;
  margin-left: 20%;
}

.details-btn {
  cursor: pointer;
  width: 18rem;
  height: 3.25rem;
  margin-top: 1rem;
  margin-left: 15rem;
  gap: 1.5px;

  padding: 0.8rem 3.1rem;

  font-family: helvetica;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: white;
  text-align: center;
  border: none;

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--button-primary-color);
}
.details-btn .MuiCircularProgress-root{
  width: 20px !important;
  height: 20px !important;
}
.details-btn:disabled {
  background: #73589ba6;
}


.details-images-left,
.details-images-right {
  display: flex;
  flex-direction: column;
  width: 80%;
  animation: img-animation 3s ease forwards;
}

.details-images-right {
  margin-left: 14rem;
  animation: float 3s ease forwards;
}

@keyframes float {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(-20%);
  }
}

.img1,
.img4 {
  margin-left: -4rem;
  margin-bottom: -3rem;
  border-radius: 50%;
  border: 2px white;
  aspect-ratio: 1/1;
  object-fit: cover;
  height: auto;
  min-width: 200px;
}

.img2,
.img3 {
  margin-left: 2rem;
  margin-bottom: -3rem;
  border-radius: 50%;
  border: 4px white;
  aspect-ratio: 1/1;
  object-fit: cover;
  height: auto;
  min-width: 200px;
}

@keyframes img-animation {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 500px) {
  .details-section {
    padding: 4rem 1rem;
    max-width: 100%;
    height: 100%;
    /* margin-top: 8rem; */
  }
  .details-main {
    width: 100%;
    margin: 0;
  }
  .details-heading {
    width: 100%;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: left;
    padding: 0;
    margin-left: 0;
  }
  .details-list {
    width: 21rem;
    height: 20rem;
    margin: 1rem 0rem;
  }
  .details-list-items {
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
  }
  .details-images-left,
  .details-images-right {
    display: none;
  }
  .details-btn {
    margin: 3rem auto 0 auto;
  }
}
