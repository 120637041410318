.InboxWidget--Item {
  & > div {
    width: 100%;
  }
  .Item--Contents {
    flex: 1;
    .Contents--Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h6 {
        color: #000;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .Item--UnreadMessages {
        padding: 2px 10px;
        border-radius: 5px;
        background: #153300;
        color: #fff;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    a {
      text-decoration: none !important;
      gap: 8px;
      margin-top: 5px;
      p {
        color: #1b8bcf;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 145.023%;
        letter-spacing: 0.21px;
      }
    }
  }

  .Item--Subject {
    margin-top: 5px;
    color: #6a6c6a;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 145.023%;
    letter-spacing: 0.21px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    width: 90%;
  }
}
